var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/components/FormEditor/index.tsx
var FormEditor_exports = {};
__export(FormEditor_exports, {
  default: () => FormEditor_default
});
module.exports = __toCommonJS(FormEditor_exports);
var import_style = require("@wangeditor/editor/dist/css/style.css");
var import_react = __toESM(require("react"));
var import_EnvVariableConfiguration = require("../../env/EnvVariableConfiguration");
var import_editor_for_react = require("@wangeditor/editor-for-react");
var import_antd = require("antd");
var import_editor = require("@wangeditor/editor");
var import_ToolbarFoldMenus = require("./ToolbarFoldMenus");
var import_index = require("./index.less");
var customUploadFile = async (file, insertFn) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("type", "wang-editor");
  const serverURL = import_EnvVariableConfiguration.UPLOAD_URL;
  const xhr = new XMLHttpRequest();
  const successFn = () => {
    let resp = JSON.parse(xhr.responseText);
    insertFn(resp.filename, "", "");
  };
  const errorFn = (response) => {
    console.error("上传失败==============", response);
    import_antd.message.error("上传失败");
  };
  xhr.addEventListener("load", successFn, false);
  xhr.addEventListener("error", errorFn, false);
  xhr.addEventListener("abort", errorFn, false);
  xhr.withCredentials = true;
  xhr.open("POST", serverURL, true);
  xhr.send(formData);
};
var FormEditor = ({
  height = "400px",
  value,
  editable,
  ...props
}) => {
  const [editor, setEditor] = (0, import_react.useState)(null);
  const [html, setHtml] = (0, import_react.useState)("");
  const isInitialRender = (0, import_react.useRef)(true);
  const toolbarConfig = props.toolbarConfig || {};
  !toolbarConfig.mode && (toolbarConfig.mode = "default");
  toolbarConfig.insertKeys = {
    index: 9999,
    keys: [import_ToolbarFoldMenus.ToolbarFoldMenusConf.key]
  };
  const editorConfig = props.editorConfig || {};
  !editorConfig.placeholder && editable && (editorConfig.placeholder = "请输入内容...");
  !editorConfig.mode && (editorConfig.mode = "default");
  !editorConfig.MENU_CONF && (editorConfig.MENU_CONF = {});
  !editable && (editorConfig.readOnly = true);
  !editorConfig.MENU_CONF["uploadImage"] && (editorConfig.MENU_CONF["uploadImage"] = {
    async customUpload(file, insertFn) {
      await customUploadFile(file, insertFn);
    }
  });
  !editorConfig.MENU_CONF["uploadVideo"] && (editorConfig.MENU_CONF["uploadVideo"] = {
    async customUpload(file, insertFn) {
      await customUploadFile(file, insertFn);
    }
  });
  (0, import_react.useEffect)(() => {
    if (value && value !== "") {
      setHtml(value);
    }
    try {
      import_editor.Boot.registerMenu(import_ToolbarFoldMenus.ToolbarFoldMenusConf);
    } catch (e) {
    }
  }, []);
  (0, import_react.useEffect)(() => {
    return () => {
      if (editor != null) {
        editor.destroy();
        setEditor(null);
        console.log("====富文本编辑器已销毁");
      }
    };
  }, [editor]);
  const handleChange = (editor2) => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    let currentHtml = editor2.getHtml();
    if (currentHtml === "<p><br></p>") {
      currentHtml = "";
    }
    setHtml(currentHtml);
    console.log("editor content changed:", currentHtml);
    props.onChange && props.onChange(currentHtml);
  };
  return /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, /* @__PURE__ */ import_react.default.createElement("div", { style: editable ? { border: "1px solid #ccc", zIndex: 100, flex: 1 } : {} }, editable && /* @__PURE__ */ import_react.default.createElement(
    import_editor_for_react.Toolbar,
    {
      editor,
      defaultConfig: toolbarConfig,
      mode: toolbarConfig.mode,
      style: { borderBottom: "1px solid #ccc" }
    }
  ), /* @__PURE__ */ import_react.default.createElement(
    import_editor_for_react.Editor,
    {
      defaultConfig: editorConfig,
      value: html,
      onCreated: setEditor,
      onChange: handleChange,
      mode: editorConfig.mode,
      style: editable ? { height, overflowY: "hidden" } : { height }
    }
  )));
};
var FormEditor_default = FormEditor;
