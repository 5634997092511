var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/services/base/ServiceRequest.ts
var ServiceRequest_exports = {};
__export(ServiceRequest_exports, {
  cascaderOptionTransfer: () => cascaderOptionTransfer,
  cascaderRequest: () => cascaderRequest,
  changePwdRequest: () => changePwdRequest,
  currentAdminRequest: () => currentAdminRequest,
  dataCascaderNodeTransfer: () => dataCascaderNodeTransfer,
  dataTreeNodeTransfer: () => dataTreeNodeTransfer,
  delRequest: () => delRequest,
  formSubmitRequest: () => formSubmitRequest,
  getPhoneCodeRequest: () => getPhoneCodeRequest,
  loadFormSchema: () => loadFormSchema,
  loadQuerySchema: () => loadQuerySchema,
  loadRecordRequest: () => loadRecordRequest,
  loadRequest: () => loadRequest,
  loginPhoneRequest: () => loginPhoneRequest,
  loginRequest: () => loginRequest,
  menuTransfer: () => menuTransfer,
  mergeRoute: () => mergeRoute,
  pageRequest: () => pageRequest,
  postRequest: () => postRequest,
  replacePageParams: () => replacePageParams,
  request: () => request,
  requestConfigs: () => requestConfigs,
  requestLogout: () => requestLogout,
  requestMenus: () => requestMenus,
  requestPageService: () => requestPageService,
  requestRoutes: () => requestRoutes,
  requestService: () => requestService,
  requestUrl: () => requestUrl,
  selectRequest: () => selectRequest,
  treeRequest: () => treeRequest
});
module.exports = __toCommonJS(ServiceRequest_exports);
var import_umi_request = require("umi-request");
var import_EnvVariableConfiguration = require("../../env/EnvVariableConfiguration");
var import_FormProvider = require("../../provider/FormProvider");
var import_max = require("@umijs/max");
var import_antd = require("antd");
var import_react = __toESM(require("react"));
var import_AppProvider = require("../../provider/AppProvider");
var CONVERT_LOADER_KEYS = [
  "iconKey",
  "nameKey",
  "descriptionKey",
  "contentKey",
  "idKey",
  "pageable",
  "code",
  "searchKey",
  "actionType",
  "params",
  "separator",
  "multiple",
  "multipleMax"
];
var request = (0, import_umi_request.extend)({
  prefix: import_EnvVariableConfiguration.API_ADDRESS,
  // suffix: '',
  timeout: import_EnvVariableConfiguration.API_TIMEOUT,
  timeoutMessage: "请求超时，请重试",
  credentials: "include",
  // 始终发送包含凭据的请求（即使是跨域源）
  // mode: 'no-cors',
  headers: {
    viewtype: "react"
  },
  errorHandler: (error) => {
    console.error(error);
    const { response } = error;
    if (response && response.status) {
      const { status, statusText, url } = response;
      if (response.status === 301) {
        if (!url.endsWith("/common/index")) {
          import_antd.notification.error({
            key: "301",
            message: "登录已失效或超时，请重新登录",
            duration: 2.5,
            onClose: () => {
            }
          });
          if (localStorage.getItem("keepAliveTabs")) {
            localStorage.removeItem("keepAliveTabs");
          }
          import_max.history.push(import_EnvVariableConfiguration.LOGIN_URL);
        }
        return;
      } else if (response.status === 403) {
        import_antd.notification.error({
          key: "403",
          message: "抱歉，你无权访问",
          duration: 2.5,
          onClose: () => {
          }
        });
        return;
      } else {
        import_antd.notification.error({
          key: "network_timeout",
          message: "网络超时，请重试",
          duration: 2.5,
          onClose: () => {
          }
        });
        return;
      }
    }
    if (!response) {
      import_antd.notification.error({
        key: "network_error",
        description: "您的网络发生异常，无法连接服务器",
        message: "网络异常",
        duration: 2.5,
        onClose: () => {
        }
      });
    }
    throw error;
  }
});
async function loginRequest(params, data, options) {
  const formData = new FormData();
  data && Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });
  return request("/login_json", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    params,
    body: formData,
    ...options || {}
  });
}
async function getPhoneCodeRequest(params, data, options) {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });
  return request("/login/phoneCode", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    params,
    body: formData,
    ...options || {}
  });
}
async function loginPhoneRequest(body, options) {
  return request("/login_phone_json", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    params: body,
    ...options || {}
  });
}
var changePwdRequest = async (oldPassword, newPassword) => {
  const resp = await request(
    "/password/change",
    {
      method: "post",
      params: { oldPassword, newPassword }
    }
  );
  console.log("[修改密码]->" + JSON.stringify(resp));
  return resp;
};
async function currentAdminRequest(options) {
  return request("/common/currentAdmin", {
    method: "post",
    headers: {
      "Content-Type": "application/json"
    },
    ...options || {}
  });
}
var selectRequest = (dataRequest, dataNames) => {
  return async (params, props) => {
    const resp = await request(
      dataRequest.url,
      {
        method: "post",
        params: { ...params, ...dataRequest.params }
      }
    );
    let records = [];
    if (resp) {
      resp.forEach((row) => {
        if (typeof row === "string") {
          records.push({
            label: row,
            value: row
          });
        } else {
          records.push({
            label: Reflect.get(row, (dataNames == null ? void 0 : dataNames.name) || "name"),
            value: Reflect.get(row, (dataNames == null ? void 0 : dataNames.id) || "id"),
            ...row
          });
        }
      });
    }
    return records;
  };
};
var pageRequest = (url) => {
  url = formatUrl(url);
  return async (params, sorter, filter) => {
    const orderBy = [];
    const orderType = [];
    if (sorter) {
      for (let s in sorter) {
        orderBy.push(s);
        orderType.push(sorter[s] === "ascend" ? "asc" : "desc");
      }
    }
    if (filter) {
      let filterKeys = [];
      let filterValues = [];
      for (let key in filter) {
        const value = filter[key];
        if (value) {
          filterKeys.push(key);
          filterValues.push(value.join("#"));
          console.log(key + "->" + value);
        }
      }
      params = { ...params, filterKeys, filterValues };
    }
    const resp = await request(
      url,
      {
        method: "post",
        params: {
          // 转换分页查询参数名
          queryPage: params == null ? void 0 : params.current,
          querySize: params == null ? void 0 : params.pageSize,
          orderBy,
          orderType,
          ...replacePageParams(params)
        }
        // TODO JSessionID每次会变化
        //credentials: "same-origin"
      }
    );
    resp == null ? void 0 : resp.records.forEach((item) => {
      var _a;
      if (Object.keys(item).includes("children") && ((_a = item["children"]) == null ? void 0 : _a.length) === 0) {
        Reflect.deleteProperty(item, "children");
      }
    });
    return {
      // 转换分页结果对象
      data: (resp == null ? void 0 : resp.records) || [],
      total: resp == null ? void 0 : resp.total,
      success: true
    };
  };
};
var requestPageService = (url, evt) => {
  url = formatUrl(url);
  return async (params, sorter, filter) => {
    var _a, _b, _c;
    const orderBy = [];
    const orderType = [];
    if (sorter) {
      for (let s in sorter) {
        orderBy.push(s);
        orderType.push(sorter[s] === "ascend" ? "asc" : "desc");
      }
    }
    if (filter) {
      for (let key in filter) {
        let filterValues = "";
        let value = filter[key];
        if (value) {
          const valueArr = value;
          valueArr.map((value2) => {
            filterValues += value2 + "#";
          });
          filterValues = filterValues.substring(0, filterValues.length - 1);
          params = { ...params, filterKeys: key, filterValues };
          console.log(key + "->" + value);
        }
      }
    }
    let queryParams = {};
    if (url.includes("?")) {
      const queryItems = url.split("?")[1].split("&");
      queryItems.forEach((queryItem) => {
        const v = queryItem.split("=");
        queryParams = { ...queryParams, [v[0]]: [v[1]] };
      });
    }
    const resp = await request(
      url,
      {
        method: "post",
        data: {
          // 转换分页查询参数名
          queryPage: params == null ? void 0 : params.current,
          querySize: params == null ? void 0 : params.pageSize,
          orderBy,
          orderType,
          ...replacePageParams(params),
          ...queryParams,
          ...evt
        },
        mode: "cors"
      }
    );
    (_a = resp == null ? void 0 : resp.data) == null ? void 0 : _a.records.forEach((item) => {
      var _a2;
      if (Object.keys(item).includes("children") && ((_a2 = item["children"]) == null ? void 0 : _a2.length) === 0) {
        Reflect.deleteProperty(item, "children");
      }
    });
    return {
      // 转换分页结果对象
      data: ((_b = resp == null ? void 0 : resp.data) == null ? void 0 : _b.records) || [],
      total: (_c = resp == null ? void 0 : resp.data) == null ? void 0 : _c.total,
      success: true
    };
  };
};
var loadRequest = (url, id, idKey, callback) => {
  url = formatUrl(url);
  console.log("url->" + url + ",id->" + id);
  return async (params) => {
    const resp = await request(
      url,
      {
        method: "post",
        params: { [idKey || "id"]: id, querySize: 1 }
      }
    );
    const data = resp.records[0];
    if (callback) {
      callback(data);
    }
    return Promise.resolve({
      success: true,
      data
    });
  };
};
var loadRecordRequest = async (url, id, idKey) => {
  url = formatUrl(url);
  console.log("url->" + url + ",id->" + id);
  const resp = await request(
    url,
    {
      method: "post",
      params: { [idKey || "id"]: id, querySize: 1 }
    }
  );
  return resp.records[0];
};
var formatUrl = (url) => {
  if (!import_EnvVariableConfiguration.API_ADDRESS.endsWith("/") && !url.startsWith("/")) {
    return "/" + url;
  }
  return url;
};
var loadQuerySchema = async (url, customColumns) => {
  url = formatUrl(url);
  let mergedColumns = await request(
    url,
    {
      method: "POST",
      params: { schema_: "true" },
      mode: "cors"
      // useCache: true,
      // ttl: 60000,
      // validateCache: (url, options) => { // @ts-ignore
      //   return options.method.toLowerCase() === 'get' },
    }
  );
  if (!Array.isArray(mergedColumns)) {
    console.info(`【${url}】-> schema not find`);
    mergedColumns = [];
  }
  customColumns == null ? void 0 : customColumns.forEach((cc) => {
    const i = mergedColumns.findIndex((mc) => mc.key === cc.key);
    if (i !== -1) {
      const items = { ...mergedColumns[i], ...cc };
      console.log("合并替换column -> ", mergedColumns[i], items);
      mergedColumns.splice(i, 1, items);
    } else {
      mergedColumns.push(cc);
    }
  });
  mergedColumns = (0, import_FormProvider.processLoader)(mergedColumns);
  mergedColumns = mergedColumns == null ? void 0 : mergedColumns.sort((c1, c2) => {
    if ((c1.index || 999) > (c2.index || 999)) {
      return 1;
    } else {
      return -1;
    }
  });
  moveLoaderToFieldProps(mergedColumns);
  console.log("=================================[" + url + "] 合并后的 query schema ->> ", mergedColumns);
  return mergedColumns || [];
};
var moveLoaderToFieldProps = (mergedColumns) => {
  mergedColumns == null ? void 0 : mergedColumns.forEach((column) => {
    CONVERT_LOADER_KEYS.forEach((key) => {
      if (column.loader && column.loader[key]) {
        if (!column.fieldProps) {
          column.fieldProps = {};
        }
        column.fieldProps = {
          ...column.fieldProps,
          [key]: column.loader[key]
        };
      }
    });
  });
};
var formFieldWidth = /* @__PURE__ */ new Map([
  ["money", 100],
  ["percent", 100],
  ["digit", 100],
  ["radio", "l"],
  ["checkbox", "l"],
  ["date", "s"],
  ["select", "s"]
]);
var loadFormSchema = async (url, customColumns, ignoreColumnKeys, bindParams) => {
  url = formatUrl(url);
  let mergedColumns = await request(
    url,
    {
      method: "GET",
      // headers: {schema_: 'true'},
      params: { schema_: "true" }
      // useCache: true,
      // ttl: 60000,
      // validateCache: (url, options) => { // @ts-ignore
      //   return options.method.toLowerCase() === 'get' },
    }
  );
  if (!Array.isArray(mergedColumns)) {
    console.info(`【${url}】-> schema not find`);
    mergedColumns = [];
  }
  customColumns == null ? void 0 : customColumns.forEach((cc) => {
    const i = mergedColumns.findIndex((mc) => mc.key === cc.key);
    if (i !== -1) {
      const items = { ...mergedColumns[i], ...cc };
      console.log("替换column -> ", mergedColumns[i], items);
      mergedColumns.splice(i, 1, items);
    } else {
      mergedColumns.push(cc);
    }
  });
  mergedColumns = (0, import_FormProvider.processLoader)(mergedColumns);
  let columnsBak = [...mergedColumns];
  if (mergedColumns) {
    mergedColumns = mergedColumns.sort((c1, c2) => {
      if ((c1.order || 0) > (c2.order || 0)) {
        return 1;
      } else {
        return -1;
      }
    });
    let i = mergedColumns.length;
    mergedColumns.forEach((item) => {
      const valueType = Reflect.get(item, "valueType");
      const width = formFieldWidth.get(valueType) || "m";
      if (Object.keys(item).indexOf("width") === -1) {
        Reflect.set(item, "width", width);
      }
      Reflect.deleteProperty(item, "order");
      if (bindParams && item.loader) {
        const bindParam = bindParams[item.key];
        if (bindParam) {
          const params = (item.loader.params ? item.loader.params + "&" : "") + bindParam;
          item.loader = { ...item.loader, params };
        }
      }
    });
  }
  if (ignoreColumnKeys) {
    mergedColumns = mergedColumns.filter((item) => !ignoreColumnKeys.includes(item.key));
  }
  let dependencyMap = /* @__PURE__ */ new Map();
  mergedColumns.forEach((item) => {
    var _a, _b;
    let cascadeTo = (_a = item.cascadeShow) == null ? void 0 : _a.cascadeTo;
    if (item.cascadeShow && cascadeTo) {
      if (dependencyMap.has(cascadeTo)) {
        (_b = dependencyMap.get(cascadeTo)) == null ? void 0 : _b.push(item);
      } else {
        dependencyMap.set(cascadeTo, [item]);
      }
    }
  });
  dependencyMap.forEach((value, key) => {
    let column = {
      // @ts-ignore
      valueType: "dependency",
      name: [key],
      columns: (item) => {
        var _a, _b;
        let columnGroup = /* @__PURE__ */ new Map();
        value.forEach((col) => {
          var _a2;
          let cascadeEq = (_a2 = col.cascadeShow) == null ? void 0 : _a2.cascadeValue;
          if (col.cascadeShow && cascadeEq != null && cascadeEq.length > 0) {
            cascadeEq.forEach((cascadeValueItem) => {
              var _a3;
              if (columnGroup.has(cascadeValueItem)) {
                (_a3 = columnGroup.get(cascadeValueItem)) == null ? void 0 : _a3.push(col);
              } else {
                columnGroup.set(cascadeValueItem, [col]);
              }
            });
          }
        });
        for (const cascadeEq of columnGroup.keys()) {
          console.log("cascade item -> ", key, (_a = item[`${key}`]) == null ? void 0 : _a.toString());
          if (((_b = item[`${key}`]) == null ? void 0 : _b.toString()) === cascadeEq) {
            return columnGroup.get(cascadeEq);
          }
        }
        return [];
      }
    };
    let order;
    let tempColumn;
    value.forEach((col, index) => {
      var _a;
      let cascadeEq = (_a = col.cascadeShow) == null ? void 0 : _a.cascadeValue;
      if (col.cascadeShow && cascadeEq != null && cascadeEq.length > 0) {
        tempColumn = col;
        return;
      }
    });
    if ((tempColumn == null ? void 0 : tempColumn.order) != null) {
      order = tempColumn.order;
    } else {
      order = columnsBak.findIndex((col) => col.key === tempColumn.key);
      if (order > 0) {
        for (let j = order; j > 0; j--) {
          let targetIndex = mergedColumns.findIndex((item) => item.key === columnsBak[j].key);
          if (targetIndex !== -1) {
            order = j + 1;
            break;
          }
        }
      }
    }
    if (order != null) {
      mergedColumns.splice(order, 0, column);
    } else {
      mergedColumns.push(column);
    }
  });
  mergedColumns = mergedColumns.filter((item) => item.cascadeShow === void 0);
  moveLoaderToFieldProps(mergedColumns);
  console.log("=================================[" + url + "] 合并后的 form schema ->> ", mergedColumns);
  return mergedColumns;
};
var cascaderRequest = async (dataRequest, dataNames, level) => {
  const resp = await request(
    dataRequest.url,
    {
      method: "post",
      params: dataRequest.params
    }
  );
  let records = [];
  if (resp && resp.length > 0) {
    resp.map((row) => {
      records.push(cascaderOptionTransfer(row, dataNames, level));
    });
  }
  return records;
};
var cascaderOptionTransfer = (obj, dataNames, maxLevel) => {
  const { level, icon } = obj;
  const isLeaf = level && maxLevel ? parseInt(level) >= maxLevel : false;
  const rowChildren = Reflect.get(obj, (dataNames == null ? void 0 : dataNames.children) || "children");
  let children = void 0;
  if (rowChildren) {
    children = [];
    rowChildren.map((childObj) => {
      children == null ? void 0 : children.push(cascaderOptionTransfer(childObj, dataNames, maxLevel));
    });
  }
  const id = Reflect.get(obj, (dataNames == null ? void 0 : dataNames.id) || "id");
  const name = Reflect.get(obj, (dataNames == null ? void 0 : dataNames.name) || "name");
  return {
    value: id,
    label: name,
    title: name,
    children,
    //level: level,
    isLeaf,
    ...obj
  };
};
var treeRequest = async (dataRequest, dataNames, level) => {
  const resp = await request(
    dataRequest.url,
    {
      method: "post",
      params: dataRequest.params
    }
  );
  let records = [];
  if (resp && resp.length > 0) {
    resp.map((row) => {
      records.push(dataTreeNodeTransfer(row, dataNames, level));
    });
  }
  return records;
};
var formSubmitRequest = async (url, evt) => {
  url = formatUrl(url);
  const resp = await request(
    url,
    {
      method: "post",
      data: evt,
      requestType: "form"
    }
  );
  console.log("[" + url + "][evt=" + JSON.stringify(evt) + "]->" + JSON.stringify(resp));
  return resp;
};
var dataTreeNodeTransfer = (obj, dataNames, maxLevel) => {
  const { level, icon } = obj;
  const isLeaf = level && maxLevel ? parseInt(level) >= maxLevel : false;
  const rowChildren = Reflect.get(obj, (dataNames == null ? void 0 : dataNames.children) || "children");
  let children = void 0;
  if (rowChildren) {
    children = [];
    rowChildren.map((childObj) => {
      children == null ? void 0 : children.push(dataTreeNodeTransfer(childObj, dataNames, maxLevel));
    });
  }
  const id = Reflect.get(obj, (dataNames == null ? void 0 : dataNames.id) || "id");
  const name = Reflect.get(obj, (dataNames == null ? void 0 : dataNames.name) || "name");
  return {
    key: id,
    title: name,
    icon,
    children,
    isLeaf,
    ...obj
  };
};
var dataCascaderNodeTransfer = (obj, dataNames, maxLevel) => {
  const { level, icon } = obj;
  const isLeaf = level && maxLevel ? parseInt(level) >= maxLevel : false;
  const rowChildren = Reflect.get(obj, (dataNames == null ? void 0 : dataNames.children) || "children");
  let children = void 0;
  if (rowChildren) {
    children = [];
    rowChildren.map((childObj) => {
      children == null ? void 0 : children.push(dataCascaderNodeTransfer(childObj, dataNames, maxLevel));
    });
  }
  const id = Reflect.get(obj, (dataNames == null ? void 0 : dataNames.id) || "id");
  const name = Reflect.get(obj, (dataNames == null ? void 0 : dataNames.name) || "name");
  const enabled = Reflect.get(obj, "enabled") || true;
  return {
    label: name,
    value: id,
    disabled: !enabled,
    // icon: icon,
    children,
    isLeaf,
    ...obj
  };
};
var delRequest = (url, ids, idsKey) => {
  url = formatUrl(url);
  console.log("url->" + url + ",[" + (idsKey || "ids") + "]->" + ids);
  return request(
    url,
    {
      method: "post",
      params: { [idsKey || "ids"]: ids }
    }
  );
};
var postRequest = (url, id, idKey, params) => {
  console.log("url->" + url + ",[" + (idKey || "id") + "]->" + id);
  if (!url.startsWith("/")) {
    url = "/" + url;
  }
  return request(
    url,
    {
      method: "post",
      data: { [idKey || "id"]: id, ...params },
      requestType: "form"
    }
  );
};
var replacePageParams = (params) => {
  const keys = Object.keys(params);
  const hasKeywordSearch = keys.includes("keyword_") && keys.includes("keywordType_");
  if (hasKeywordSearch) {
    const { keywordType_, keyword_ } = params;
    if (keyword_ && keyword_ !== "" && keywordType_ && keywordType_ !== "") {
      params = { ...params, [keywordType_]: keyword_ };
      Reflect.deleteProperty(params, "keywordType_");
      Reflect.deleteProperty(params, "keyword_");
    }
  }
  keys.map((key) => {
    if (key.endsWith("Range")) {
      const value = Reflect.get(params, key);
      if (value) {
        let values = [...value];
        if (values && values.length > 0) {
          let endName = key.substring(0, 1).toUpperCase() + key.substring(1, key.length - 5);
          params = { ...params, ["min" + endName]: values[0] };
          if (values.length > 1) {
            params = { ...params, ["max" + endName]: values[1] };
          }
          Reflect.deleteProperty(params, key);
        }
      }
    }
  });
  console.log("submit params->" + JSON.stringify(params));
  return params;
};
var requestLogout = async () => {
  return await request("/logout", {
    method: "POST"
  });
};
var requestRoutes = () => {
  return request("/routes", {
    method: "POST"
  });
};
var requestUrl = (url, params) => {
  url = formatUrl(url);
  return request(url, {
    method: "POST",
    data: params
  });
};
var menuTransfer = (menuItem) => {
  const { children, disabled, enabled, id, pathIds, url, name, icon, level } = menuItem;
  let newVar = {
    key: `${id}`,
    parentKeys: pathIds,
    path: url.endsWith("/index") ? `/${url.substring(0, url.lastIndexOf("/"))}` : `/${url}`,
    name,
    icon: icon ? (icon == null ? void 0 : icon.startsWith("ico-system")) ? "icon-shoucang1" : icon : level < 3 ? "icon-shoucang1" : void 0,
    //children: children?.map(menuTransfer),
    routes: children == null ? void 0 : children.map(menuTransfer),
    hideInMenu: enabled === false,
    disabled: disabled === true,
    hideInBreadcrumb: true
  };
  if (level === 3) {
    return { ...newVar, access: "check" };
  }
  return newVar;
};
var requestMenus = async (params, defaultMenuData) => {
  const resp = await request(
    "/common/menus",
    {
      method: "post",
      params
    }
  );
  let records = [];
  if (resp && resp.length > 0) {
    resp.map((row) => {
      return records.push(menuTransfer(row));
    });
  }
  if (defaultMenuData && defaultMenuData.length > 0) {
    if (records.length === 1) {
      records[0].routes = [...defaultMenuData, ...records[0].routes];
    } else {
      records = [...defaultMenuData, ...records];
    }
  }
  records.forEach((first) => {
    var _a, _b, _c, _d;
    const redirectMenuPath = (_c = (_b = (_a = first.routes) == null ? void 0 : _a[0].routes) == null ? void 0 : _b[0]) == null ? void 0 : _c.path;
    if (redirectMenuPath) {
      let path = redirectMenuPath.substring(0, redirectMenuPath.lastIndexOf("/"));
      (_d = first.routes) == null ? void 0 : _d.unshift({
        path,
        redirect: redirectMenuPath
        // parentKeys: first.routes?.[0].routes?.[0]?.parentKeys
      });
      first.path = path;
    }
  });
  return Promise.resolve(records);
};
var requestConfigs = async () => {
  return await request(
    "/common/config",
    {
      method: "post"
    }
  );
};
var requestService = (url, evt) => {
  url = formatUrl(url);
  console.log("evt -> ", evt);
  return request(
    url,
    {
      method: "POST",
      data: { ...evt },
      mode: "cors"
    }
  );
};
var findLayoutRoute = (routes) => {
  return routes.find((route) => route.id === "ant-design-pro-layout");
};
var mergeRoute = (routes, loadRoutes) => {
  var _a, _b, _c, _d;
  console.log("loadRoutes -> ", routes, loadRoutes);
  let layoutRoute = findLayoutRoute(routes);
  console.log("layoutRoute -> ", layoutRoute);
  loadRoutes == null ? void 0 : loadRoutes.map((loadRoute) => {
    var _a2, _b2, _c2, _d2, _e, _f, _g, _h;
    let findIndex = ((_a2 = layoutRoute == null ? void 0 : layoutRoute.routes) == null ? void 0 : _a2.findIndex((r) => r.path === loadRoute.path || "/" + r.path === loadRoute.path)) || -1;
    const hasExists = findIndex > 0;
    if (hasExists) {
      loadRoute.element = (_b2 = layoutRoute == null ? void 0 : layoutRoute.routes) == null ? void 0 : _b2[findIndex].element;
      (_c2 = layoutRoute == null ? void 0 : layoutRoute.routes) == null ? void 0 : _c2.splice(findIndex, 1, loadRoute);
    } else {
      const action = ((_e = loadRoute.path) == null ? void 0 : _e.substr(((_d2 = loadRoute.path) == null ? void 0 : _d2.lastIndexOf("/")) + 1)) || "";
      let findDynamicIndex = (layoutRoute == null ? void 0 : layoutRoute.routes.findIndex((r) => r.path && (r.path.startsWith("dynamic/") || r.path.startsWith("/dynamic/")) && r.path.endsWith(action))) || -1;
      console.log("动态组件 -> " + loadRoute.path + " -> " + action + ",findDynamicIndex = " + findDynamicIndex);
      if (findDynamicIndex !== -1) {
        loadRoute.element = import_react.default.cloneElement((_g = (_f = layoutRoute == null ? void 0 : layoutRoute.routes) == null ? void 0 : _f[findDynamicIndex]) == null ? void 0 : _g.element, {
          key: Math.random(),
          // 必须设置不同的key
          value: {
            route: {
              id: loadRoute.path,
              path: loadRoute.path,
              parentId: "ant-design-pro-layout",
              title: loadRoute.title
              // 设置Title
            }
          }
        });
      }
      loadRoute.id = loadRoute.path;
      loadRoute.parentId = "ant-design-pro-layout";
      if (layoutRoute && !(layoutRoute == null ? void 0 : layoutRoute.routes)) {
        layoutRoute.routes = [];
      }
      (_h = layoutRoute == null ? void 0 : layoutRoute.routes) == null ? void 0 : _h.push(loadRoute);
    }
    return loadRoute;
  });
  let findLoginRouteIndex = ((_a = layoutRoute == null ? void 0 : layoutRoute.routes) == null ? void 0 : _a.findIndex((r) => "/" + r.path === import_EnvVariableConfiguration.LOGIN_URL)) || -1;
  console.log("findLoginRouteIndex=" + findLoginRouteIndex);
  if (findLoginRouteIndex !== -1) {
    (_c = layoutRoute == null ? void 0 : layoutRoute.routes) == null ? void 0 : _c.splice(findLoginRouteIndex, 1, {
      ...(_b = layoutRoute == null ? void 0 : layoutRoute.routes) == null ? void 0 : _b[findLoginRouteIndex],
      layout: false
    });
    const loginRoute = (_d = layoutRoute == null ? void 0 : layoutRoute.routes) == null ? void 0 : _d[findLoginRouteIndex];
    routes.unshift(loginRoute);
  }
  if (import_EnvVariableConfiguration.LAYOUT_NONE_URL !== "") {
    let urls = import_EnvVariableConfiguration.LAYOUT_NONE_URL.split(",");
    urls.forEach((url) => {
      var _a2, _b2, _c2;
      let findIndex = ((_a2 = layoutRoute == null ? void 0 : layoutRoute.routes) == null ? void 0 : _a2.findIndex((r) => r.path === url)) || -1;
      if (findIndex !== -1) {
        (_c2 = layoutRoute == null ? void 0 : layoutRoute.routes) == null ? void 0 : _c2.splice(findIndex, 1, { ...(_b2 = layoutRoute == null ? void 0 : layoutRoute.routes) == null ? void 0 : _b2[findIndex], layout: false });
      }
    });
  }
  batchAddTitle(routes);
  console.log("mergeRoute -> ", routes);
};
var batchAddTitle = async (routes) => {
  let result = await (0, import_AppProvider.initialState)(import_max.history);
  let menus = result.menus || [];
  console.log(menus);
  batchAddTitleIfBlank(routes, menus);
};
var batchAddTitleIfBlank = async (routes, menus) => {
  routes.forEach((route) => {
    if (route.title === void 0) {
      route.title = findMenuNameByPath(menus, route.path);
    }
    if (route.children) {
      batchAddTitleIfBlank(route.children, menus);
    }
  });
};
var findMenuNameByPath = (menus, path) => {
  for (let menu of menus) {
    if (menu.path === path || menu.path === "/" + path) {
      return menu.name;
    }
    if (menu.routes) {
      let result = findMenuNameByPath(menu.routes, path);
      if (result) {
        return result;
      }
    }
  }
  return void 0;
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  cascaderOptionTransfer,
  cascaderRequest,
  changePwdRequest,
  currentAdminRequest,
  dataCascaderNodeTransfer,
  dataTreeNodeTransfer,
  delRequest,
  formSubmitRequest,
  getPhoneCodeRequest,
  loadFormSchema,
  loadQuerySchema,
  loadRecordRequest,
  loadRequest,
  loginPhoneRequest,
  loginRequest,
  menuTransfer,
  mergeRoute,
  pageRequest,
  postRequest,
  replacePageParams,
  request,
  requestConfigs,
  requestLogout,
  requestMenus,
  requestPageService,
  requestRoutes,
  requestService,
  requestUrl,
  selectRequest,
  treeRequest
});
