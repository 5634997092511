var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/components/FormSelectPlus/index.tsx
var FormSelectPlus_exports = {};
__export(FormSelectPlus_exports, {
  default: () => FormSelectPlus_default
});
module.exports = __toCommonJS(FormSelectPlus_exports);
var import_pro_components = require("@ant-design/pro-components");
var import_antd = require("antd");
var import_react = __toESM(require("react"));
var import_index = require("./index.less");
var import_ServiceRequest = require("../../services/base/ServiceRequest");
var import_Search = __toESM(require("antd/es/input/Search"));
var import_icons = require("@ant-design/icons");
var FormSelectPlus = ({
  editable = false,
  isDetail = false,
  isEdit = false,
  onChange,
  value,
  ...props
}) => {
  const [selectedItem, setSelectedItem] = (0, import_react.useState)();
  const [recordData, setRecordData] = (0, import_react.useState)([]);
  const [pages, setPages] = (0, import_react.useState)({
    pageSize: 10,
    pageNo: 1,
    total: 0
  });
  const [newPages, setNewPages] = (0, import_react.useState)({
    pageSize: 10,
    pageNo: 1,
    total: 0
  });
  const [keyword, setKeyword] = (0, import_react.useState)("");
  const [previewInfo, setPreviewInfo] = (0, import_react.useState)({ show: false });
  const { message } = import_antd.App.useApp();
  const onVisibleChange = (value2) => {
    setPreviewInfo((pre) => {
      return { ...pre, show: value2 };
    });
  };
  const {
    idKey,
    iconKey,
    nameKey,
    descriptionKey,
    contentKey,
    searchKey,
    pageable = false,
    actionType,
    multiple = false,
    multipleMax = -1
  } = props.fieldProps;
  if (multipleMax > 0) {
    props.fieldProps.maxCount = multipleMax;
    props.fieldProps.rules = [{
      validator: (_, value2) => {
        if (value2 && value2.length > multipleMax) {
          return Promise.reject(`最多选择${multipleMax}个`);
        }
        return Promise.resolve();
      }
    }];
  }
  (0, import_react.useEffect)(() => {
    if (!selectedItem) {
      onChange == null ? void 0 : onChange(void 0);
      return;
    }
    onChange == null ? void 0 : onChange(Array.isArray(selectedItem) ? selectedItem.map((item) => item.value).join(",") : selectedItem.value || void 0);
  }, [selectedItem]);
  (0, import_react.useEffect)(() => {
    reselect();
  }, [props.record]);
  const fetchData = async () => {
    var _a;
    let data = {};
    if (multiple) {
      data["filterKeys"] = ["id"];
      data["filterValues"] = [props.text.split(",").join("#")];
    } else {
      data[idKey] = value;
    }
    const result = await (0, import_ServiceRequest.request)(
      `/${(_a = props.fieldProps) == null ? void 0 : _a.code}/select`,
      {
        method: "post",
        data,
        requestType: "form"
      }
    );
    if (!result) {
      message.error("数据不存在");
      return null;
    }
    return result;
  };
  const buildSelectedItem = (item) => {
    return {
      value: item[idKey],
      thumbImage: item[iconKey],
      title: item[nameKey],
      desc: item[descriptionKey],
      url: item[contentKey]
    };
  };
  const reselect = async () => {
    let record = props.record;
    if (!record)
      return;
    if (isEdit) {
      if (!idKey || !value) {
        return;
      }
      const data = await fetchData();
      if (!data || data.length === 0) {
        return;
      }
      if (multiple) {
        let mapResult = data.map((item) => {
          return buildSelectedItem(item);
        });
        setRecordData(mapResult);
        setSelectedItem(mapResult);
      } else {
        setSelectedItem(buildSelectedItem(data[0]));
      }
    } else {
      if (multiple) {
        const data = await fetchData();
        if (!data || data.length === 0) {
          return;
        }
        let mapResult = data.map((item) => {
          return buildSelectedItem(item);
        });
        setRecordData(mapResult);
        setSelectedItem(mapResult);
      } else {
        setSelectedItem(buildSelectedItem(record));
      }
    }
  };
  const getList = async (pagination, keyword2) => {
    var _a, _b;
    let queryString = (_a = props.fieldProps) == null ? void 0 : _a.params;
    const newPagination = {
      ...pages,
      ...pagination
    };
    const params = {
      pageSize: newPagination.pageSize,
      current: newPagination.pageNo
    };
    if (searchKey) {
      params[searchKey] = keyword2;
    }
    const resp = await (0, import_ServiceRequest.pageRequest)(`/${(_b = props.fieldProps) == null ? void 0 : _b.code}/page${queryString ? "?" + queryString : ""}`)(params);
    if (!resp.success) {
      message.error(resp.message);
      return;
    }
    newPagination.total = resp.total || 0;
    setPages(newPagination);
    return resp.data || [];
  };
  const handlePreviewClick = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    setPreviewInfo((pre) => {
      return { ...pre, url, show: true };
    });
  };
  const getReadonlyImg = () => {
    if (!selectedItem || Array.isArray(selectedItem)) {
      return /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null);
    }
    switch (actionType) {
      case "PREVIEW_VIDEO" /* PREVIEW_VIDEO */:
        if (selectedItem && selectedItem.thumbImage) {
          return /* @__PURE__ */ import_react.default.createElement("div", { className: "justify-content-center" }, /* @__PURE__ */ import_react.default.createElement(
            import_antd.Image,
            {
              width: 32,
              height: 32,
              src: selectedItem.thumbImage,
              preview: false,
              className: "justify-content-center cursor-pointer"
            }
          ));
        } else {
          return /* @__PURE__ */ import_react.default.createElement(
            "div",
            {
              className: "justify-content-center cursor-pointer",
              style: { width: 32, height: 32, display: "contents" }
            },
            /* @__PURE__ */ import_react.default.createElement(import_icons.VideoCameraOutlined, { style: { fontSize: 32 } })
          );
        }
      case "PLAY_AUDIO" /* PLAY_AUDIO */:
        return /* @__PURE__ */ import_react.default.createElement(
          "div",
          {
            className: "justify-content-center cursor-pointer",
            style: { width: 32, height: 32, display: "contents" }
          },
          /* @__PURE__ */ import_react.default.createElement(import_icons.CustomerServiceOutlined, { style: { fontSize: 32 } })
        );
      default:
        return /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, selectedItem.thumbImage ? /* @__PURE__ */ import_react.default.createElement(
          import_antd.Image,
          {
            width: 32,
            height: 32,
            src: selectedItem.thumbImage,
            preview: false,
            className: "cursor-pointer"
          }
        ) : "-");
    }
  };
  const getOptionImg = (item, option) => {
    switch (actionType) {
      case "PREVIEW_VIDEO" /* PREVIEW_VIDEO */:
        return /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, item && item.thumbImage || option ? /* @__PURE__ */ import_react.default.createElement("div", { className: "justify-content-center" }, /* @__PURE__ */ import_react.default.createElement(
          import_antd.Image,
          {
            width: option ? 36 : 24,
            height: option ? 36 : 24,
            src: option ? option.data.thumbImage : item.thumbImage,
            preview: false,
            className: "justify-content-center"
          }
        )) : /* @__PURE__ */ import_react.default.createElement(
          "div",
          {
            className: "justify-content-center",
            style: { width: option ? 36 : 24, height: option ? 36 : 24, display: "contents" }
          },
          /* @__PURE__ */ import_react.default.createElement(import_icons.VideoCameraOutlined, { style: { fontSize: option ? 36 : 24 } })
        ));
      case "PLAY_AUDIO" /* PLAY_AUDIO */:
        return /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, (item && item.thumbImage || option) && /* @__PURE__ */ import_react.default.createElement(
          "div",
          {
            className: "justify-content-center",
            style: { width: option ? 36 : 24, height: option ? 36 : 24, display: "contents" }
          },
          /* @__PURE__ */ import_react.default.createElement(import_icons.CustomerServiceOutlined, { style: { fontSize: option ? 36 : 24 } })
        ));
      default:
        return /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, (item && item.thumbImage || option) && /* @__PURE__ */ import_react.default.createElement("div", { className: "justify-content-center" }, /* @__PURE__ */ import_react.default.createElement(
          import_antd.Image,
          {
            className: "justify-content-center",
            width: option ? 36 : 24,
            height: option ? 36 : 24,
            src: option ? option.data.thumbImage : item.thumbImage,
            preview: false
          }
        )));
    }
  };
  const getRecordAvatarGroup = (size) => {
    return (
      // @ts-ignore
      /* @__PURE__ */ import_react.default.createElement(
        import_antd.Avatar.Group,
        {
          max: { count: 3, style: { color: "#f56a00", backgroundColor: "#fde3cf" } },
          shape: "square",
          size
        },
        recordData.map((item) => {
          return /* @__PURE__ */ import_react.default.createElement(import_antd.Tooltip, { title: item.title }, item.thumbImage ? /* @__PURE__ */ import_react.default.createElement(import_antd.Avatar, { key: item.value, src: item.thumbImage }) : /* @__PURE__ */ import_react.default.createElement(import_antd.Avatar, { key: item.value, style: { backgroundColor: "#87d068" }, gap: 1 }, item.title));
        })
      )
    );
  };
  return /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, /* @__PURE__ */ import_react.default.createElement(import_pro_components.ProFormText, { hidden: true, ...props, fieldProps: {
    ...props.fieldProps
  } }), /* @__PURE__ */ import_react.default.createElement(
    import_antd.Image.PreviewGroup,
    {
      items: [previewInfo.url || ""],
      preview: {
        visible: previewInfo.show,
        destroyOnClose: true,
        countRender: () => null,
        onVisibleChange,
        imageRender: (originalNode, info) => {
          if (actionType === "PREVIEW_VIDEO" /* PREVIEW_VIDEO */) {
            return /* @__PURE__ */ import_react.default.createElement("video", { muted: true, controls: true, src: previewInfo.url, style: { maxHeight: "80%", maxWidth: "80%" } });
          } else if (actionType === "PREVIEW_IMAGE" /* PREVIEW_IMAGE */) {
            return originalNode;
          } else if (actionType === "PLAY_AUDIO" /* PLAY_AUDIO */) {
            return /* @__PURE__ */ import_react.default.createElement("audio", { controls: true, src: previewInfo.url, style: { maxHeight: "80%", maxWidth: "80%" } });
          } else {
            return null;
          }
        }
      }
    },
    !editable && !isDetail && /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, selectedItem && !Array.isArray(selectedItem) ? /* @__PURE__ */ import_react.default.createElement(import_antd.Tooltip, { title: selectedItem.title }, /* @__PURE__ */ import_react.default.createElement("div", { style: { width: "100%", textAlign: "center" } }, /* @__PURE__ */ import_react.default.createElement(
      "div",
      {
        style: { width: 32, height: 32, display: "inline" },
        onClick: (e) => {
          handlePreviewClick(e, selectedItem.url);
        }
      },
      getReadonlyImg()
    ))) : multiple && recordData.length > 0 ? getRecordAvatarGroup("default") : "-"),
    !editable && isDetail && /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, selectedItem && !Array.isArray(selectedItem) ? /* @__PURE__ */ import_react.default.createElement(
      "div",
      {
        style: { display: "flex" },
        onClick: (e) => {
          handlePreviewClick(e, selectedItem.url);
        }
      },
      getReadonlyImg(),
      /* @__PURE__ */ import_react.default.createElement(
        "div",
        {
          className: "no-wrap justify-content-center",
          style: {
            marginLeft: 5,
            maxWidth: 200
          }
        },
        selectedItem.title
      )
    ) : multiple && recordData.length > 0 ? getRecordAvatarGroup("large") : "-"),
    editable && /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, multiple, /* @__PURE__ */ import_react.default.createElement(
      import_pro_components.ProFormSelect,
      {
        mode: multiple ? "multiple" : "single",
        params: {
          ...props.params,
          ...newPages,
          keyword
        },
        request: async (params) => {
          let result = !pageable ? await props.request() : await getList({
            pageSize: params.pageSize,
            pageNo: params.pageNo
          }, params.keyword || "");
          return (result == null ? void 0 : result.map((item) => {
            return {
              value: item[idKey],
              thumbImage: item[iconKey],
              title: item[nameKey],
              desc: item[descriptionKey],
              url: item[contentKey]
            };
          })) || [];
        },
        fieldProps: {
          ...props.fieldProps,
          value: selectedItem,
          mode: multiple ? "multiple" : void 0,
          onChange: (value2, option) => {
            if (multiple && Array.isArray(value2) && recordData.length > 0) {
              value2.forEach((item) => {
                if (!item.title && item.value != null) {
                  let data = recordData.find((i) => i.value == item.value) || selectedItem.find((i) => i.value == item.value);
                  if (data) {
                    item.title = data.title;
                    item.thumbImage = data.thumbImage;
                    item.url = data.url;
                    item.desc = data.desc;
                  }
                }
              });
            }
            setSelectedItem(value2);
          },
          // tagRender: (props) => {
          //   const {label, value, closable, onClose} = props;
          //   console.log('tagRender===', props)
          //   const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
          //     event.preventDefault();
          //     event.stopPropagation();
          //   };
          //   return (
          //     <Tag
          //       key={value}//添加key
          //       onMouseDown={onPreventMouseDown}
          //       closable={closable}
          //       onClose={onClose}
          //       style={{marginRight: 3}}
          //     >
          //       {label}
          //     </Tag>
          //   );
          // },
          labelInValue: true,
          labelRender: (item) => {
            if (!selectedItem) {
              return /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null);
            }
            if (Array.isArray(selectedItem)) {
              let option = selectedItem.find((i) => i.value == item.value);
              return /* @__PURE__ */ import_react.default.createElement("div", { style: { display: "flex" } }, getOptionImg(option), /* @__PURE__ */ import_react.default.createElement(
                "div",
                {
                  className: "no-wrap",
                  style: {
                    marginLeft: 5,
                    maxWidth: 200
                  }
                },
                option.title
              ));
            }
            return /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, /* @__PURE__ */ import_react.default.createElement("div", { style: { display: "flex" } }, getOptionImg(selectedItem), /* @__PURE__ */ import_react.default.createElement(
              "div",
              {
                className: "no-wrap",
                style: {
                  marginLeft: 5,
                  maxWidth: 200
                }
              },
              selectedItem.title
            )));
          },
          optionRender: (option) => {
            var _a;
            console.log("tagRender===", option);
            if (Array.isArray(selectedItem) && !((_a = option.data) == null ? void 0 : _a.title) && option.value != null) {
              let data = selectedItem.find((i) => i.value == option.value);
              console.log("tagRender===find", data);
              if (data) {
                option.data.title = data.title;
                option.data.thumbImage = data.thumbImage;
                option.data.url = data.url;
                option.data.desc = data.desc;
              }
            }
            return /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, /* @__PURE__ */ import_react.default.createElement("div", { style: { display: "flex", justifyContent: "space-between" } }, /* @__PURE__ */ import_react.default.createElement("div", { style: { display: "flex" } }, option.data.thumbImage && getOptionImg(null, option), /* @__PURE__ */ import_react.default.createElement("div", { style: { maxWidth: 170, paddingInlineStart: 10 } }, /* @__PURE__ */ import_react.default.createElement("div", { className: "no-wrap" }, option.data.title), option.data.desc && /* @__PURE__ */ import_react.default.createElement("div", { className: "no-wrap" }, option.data.desc))), option.data.url && /* @__PURE__ */ import_react.default.createElement("div", { className: "justify-content-center" }, /* @__PURE__ */ import_react.default.createElement(import_antd.Button, { type: "link", onClick: (e) => {
              handlePreviewClick(e, option.data.url);
            } }, actionType === "PLAY_AUDIO" /* PLAY_AUDIO */ ? "播放" : "预览"))));
          },
          dropdownRender: (menu) => {
            return /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, menu, pageable && /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, /* @__PURE__ */ import_react.default.createElement(import_antd.Divider, { style: { margin: "8px 0" } }), /* @__PURE__ */ import_react.default.createElement("div", { className: "justify-content-center", style: { cursor: "default" } }, searchKey && /* @__PURE__ */ import_react.default.createElement(import_Search.default, { allowClear: true, enterButton: true, onSearch: (value2) => {
              setKeyword(value2);
              setNewPages((pre) => {
                return { ...pre, pageNo: 1 };
              });
            }, style: { minWidth: "50%" } }), /* @__PURE__ */ import_react.default.createElement(
              import_antd.Pagination,
              {
                className: "select-plus-pagination",
                simple: { readOnly: true },
                size: "small",
                pageSize: pages.pageSize,
                current: pages.pageNo,
                total: pages.total,
                onChange: (pageNo, pageSize) => {
                  setNewPages({ pageNo, pageSize });
                }
              }
            ))));
          }
        }
      }
    ))
  ));
};
var FormSelectPlus_default = FormSelectPlus;
