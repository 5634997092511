var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/components/FormEditor/ToolbarFoldMenus.ts
var ToolbarFoldMenus_exports = {};
__export(ToolbarFoldMenus_exports, {
  ToolbarFoldMenus: () => ToolbarFoldMenus,
  ToolbarFoldMenusConf: () => ToolbarFoldMenusConf
});
module.exports = __toCommonJS(ToolbarFoldMenus_exports);
var ignoreMenus = [
  "bold",
  "fontSize",
  "color",
  "fullScreen"
];
var ToolbarFoldMenus = class {
  constructor() {
    this.title = "折叠";
    this.mode = "simple";
    this.hiddenMenus();
    this.alwaysEnable = true;
    this.iconSvg = '<svg t="1736581928454" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1254" width="200" height="200"><path d="M970 697.4H378.4c-28.8 0-52-25-52-55.6s23.2-55.6 52-55.6H970c28.8 0 52 25 52 55.6s-23.2 55.6-52 55.6z m0-259.6H378.4c-28.8 0-52-25-52-55.6s23.2-55.6 52-55.6H970c28.8 0 52 25 52 55.6s-23.2 55.6-52 55.6z m-3.6-250.4H57.6C27 187.4 2 162.4 2 131.8s25-55.6 55.6-55.6h908.8c30.6 0 55.6 25 55.6 55.6s-25 55.6-55.6 55.6z m-704.8 510L2 512l259.6-185.4v370.8z m-204 139.2h908.8c30.6 0 55.6 25 55.6 55.6s-25 55.6-55.6 55.6H57.6C27 947.8 2 922.8 2 892.2s25-55.6 55.6-55.6z m0 0" p-id="1255"></path></svg>';
    this.tag = "button";
  }
  // 菜单是否需要激活（如选中加粗文本，“加粗”菜单会激活），用不到则返回 false
  isActive() {
    return false;
  }
  // 获取菜单执行时的 value ，用不到则返回空 字符串或 false
  getValue() {
    return "";
  }
  // 菜单是否需要禁用（如选中 H1 ，“引用”菜单被禁用），用不到则返回 false
  isDisabled(editor) {
    return false;
  }
  // 点击菜单时触发的函数
  exec(editor) {
    this.mode = this.mode === "simple" ? "default" : "simple";
    this.hiddenMenus();
  }
  hiddenMenus() {
    let toolbarNodes = document.getElementsByClassName("w-e-bar w-e-bar-show w-e-toolbar");
    for (let toolbarNode of toolbarNodes) {
      for (let i = 0; i < toolbarNode.children.length; i++) {
        let tool = toolbarNode.children[i];
        if (this.mode === "simple") {
          let button = tool.getElementsByTagName("button")[0];
          if (button) {
            let menuKey = button == null ? void 0 : button.getAttribute("data-menu-key");
            if (menuKey && ignoreMenus.includes(menuKey)) {
              continue;
            }
          }
          if (i !== toolbarNode.children.length - 1) {
            tool.classList.add("display-none");
          }
        } else {
          tool.classList.remove("display-none");
        }
      }
    }
  }
};
var ToolbarFoldMenusConf = {
  key: "foldMenus",
  // 定义 menu key ：要保证唯一、不重复（重要）
  factory() {
    return new ToolbarFoldMenus();
  }
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  ToolbarFoldMenus,
  ToolbarFoldMenusConf
});
